import { React, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from "@emailjs/browser";
import "../styles/secondpage.css";
import "../styles/secondpage-responsive.css";

function Secondpage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidePanelRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMonthly, setIsMonthly] = useState(true);
  const [practitioners, setPractitioners] = useState([]);

  const pricing = {
    basic: { monthly: 149, yearly: 1490 }, // Yearly is a discounted value
    standard: { monthly: 299, yearly: 2990 },
    premium: { monthly: 495, yearly: 4950 },
  };

  const form = useRef("Full_Name", "email");

  // Fetch practitioners from API
  useEffect(() => {
    const fetchPractitioners = async () => {
      try {
        const response = await fetch(
          "https://portal.virtualtriage.ca/api/v1/doctor/insert-doctors/"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch practitioners");
        }
        const data = await response.json();
        setPractitioners(data.data || []);
      } catch (error) {
        console.error("Error fetching practitioners:", error);
      }
    };

    fetchPractitioners();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    );
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close the menu when clicking outside of the side panel
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Filter practitioners based on the name search term
  const filteredPractitioners = practitioners.filter((practitioner) =>
    practitioner.Full_Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="secondpage-container">
      {/* Header */}
      <header className="header">
        <nav className="nav">
          <div className="Nav-logo">
            <a href="Homepage" style={{ cursor: "default" }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/VTLogo.png`}
                alt="VirtualTriage"
                style={{ width: "40vh" }}
              />
            </a>
          </div>
          <div
            className="navbar-hamburger"
            ref={hamburgerRef}
            onClick={toggleMenu}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div
            className={`side-panel ${isMenuOpen ? "open" : ""}`}
            ref={sidePanelRef}
          >
            <div className="nav-links">
              <a href="./Homepage">
                <button>Users</button>
              </a>
              <a href="./Secondpage">
                <button>Practitioners</button>
              </a>
              {/* <a href="./Practitioners">
                <button>Find Practitioner</button>
              </a> */}
              <button className="button-button-border">How It Works</button>
              <a href="https://portal.virtualtriage.ca/login">
                <button className="button-button-login">Login →</button>
              </a>
            </div>
          </div>
        </nav>
      </header>

      {/* Main Banner */}
      <section className="banner">
        <div className="banner-text">
          <h1>
            Empower Your Health and Wellness Practice with AI-Driven Virtual
            Care.
          </h1>
          <p>
            Offer professional, secure consultations with our AI-powered
            platform. Virtual Triage helps you automate tasks, track
            consultations, reduce admin work, and deliver exceptional care
            anytime, anywhere.
          </p>
          <a href="https://portal.virtualtriage.ca/login">
            <button className="get-started-btn">Get Started</button>
          </a>
        </div>
        <div className="banner-image">
          <img src="../assets/practitioner side.png" alt="Mobile UI" />
        </div>
      </section>
      <div className="find-body">
        {/* <header className="header">
          <nav className="nav">
            <div className="Nav-logo">
              <img
                src={`${process.env.PUBLIC_URL}/assets/VTLogo.png`}
                alt="VirtualTriage"
                style={{ width: "40vh" }}
              />
            </div>
            <div
              className="navbar-hamburger"
              ref={hamburgerRef}
              onClick={toggleMenu}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
            <div
              className={`side-panel ${isMenuOpen ? "open" : ""}`}
              ref={sidePanelRef}
            >
              <div className="nav-links">
                <a href="./Homepage">
                  <button>Users</button>
                </a>
                <a href="./Secondpage">
                  <button>Practitioners</button>
                </a>
                {/* <a href="./Practitioners">
                  <button>Find Practitioner</button>
                </a> */}
        {/* <button className="button-button-border">How It Works</button>
                <a href="https://portal.virtualtriage.ca/login">
                  <button className="button-button-login">Login →</button>
                </a>
              </div>
            </div>
          </nav>
        </header> */}
        {/* <section className="find-banner">
          <div className="find-hero-text">
            <h1 className="find-practitioners-banner-h1">
              Find Trusted{" "}
              <strong style={{ color: "#00a3bb" }}>
                Healthcare Practitioners
              </strong>{" "}
              Near You &{" "}
              <strong style={{ color: "#00a3bb" }}>Book Appointments</strong>{" "}
              with Ease!
            </h1>
            <p>
              Discover and connect with experienced healthcare practitioners
              near you. Use our advanced search filters to find the right
              specialist by name, location, or specialty, and book appointments
              effortlessly for personalized care.
            </p>
            <div className="search-container">
              <div className="search-item">
                <label>Search by Name</label>
                <input
                  type="text"
                  placeholder="Search practitioners"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="search-item">
                <label>Search by Location</label>
                <input type="text" id="location" placeholder="Enter Location" />
              </div>
              <div className="search-item">
                <label>Search by Specialty</label>
                <input
                  type="text"
                  id="specialty"
                  placeholder="Enter Specialty"
                />
              </div>
            </div>
          </div>
          <div className="find-hero-image">
            <img src="../assets/find-doctor.png" alt="Find Practitioners" />
          </div>
        </section> */}

        {/* Practitioner List */}
        {searchTerm && (
          <section className="practitioner-list">
            <h2>Practitioner Results</h2>
            {filteredPractitioners.length > 0 ? (
              <ul>
                {filteredPractitioners.map((practitioner) => (
                  <li
                    key={practitioner.Full_Name}
                    className="practitioner-item"
                  >
                    <Link
                      to={`/profile/${encodeURIComponent(practitioner.Full_Name)}`}
                      onClick={() =>
                        console.log("Navigating with state:", practitioner)
                        }
                        state={{ practitioner }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <h3>{practitioner.Full_Name}</h3>
                      <p>Country: {practitioner.Country}</p>
                      <p>Specialty: {practitioner.Doctor_Speciality}</p>
                      {practitioner.registered ? (
                        <button disabled className="registered-btn">
                          Verified Profile
                        </button>
                      ) : (
                        <button disabled className="not-registered-btn">
                          Unverified Profile
                        </button>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No practitioners found.</p>
            )}
          </section>
        )}
      </div>

      {/* Features Section */}
      <section className="secondpage-features-section">
        <div className="secondpage-features-img">
          <img src="./assets/visuals-1.png" alt="Features" />
        </div>
        <div className="secondpage-features-text">
          <h2>Our Features</h2>
          <p>
            Few good reasons why you should use Virtual Triage as your health
            and wellness assistant.
          </p>
          <div className="secondpage-features">
            <div className="secondpage-feature-item">
              <img src="./assets/human.svg" alt="AI-Powered Symptom Checker" />
              <span>AI-Powered Symptom Checker</span>
            </div>
            <div className="secondpage-feature-item">
              <img
                src="./assets/virtualcall.svg"
                alt="Global Virtual Consultations"
              />
              <span>Global Virtual Consultations</span>
            </div>
            <div className="secondpage-feature-item">
              <img
                src="./assets/calendar.svg"
                alt="Easy Appointment Management"
              />
              <span>Easy Appointment Management</span>
            </div>
            <div className="secondpage-feature-item">
              <img src="./assets/e-payment.svg" alt="Secure E-Payments" />
              <span>Secure E-Payments</span>
            </div>
          </div>
        </div>
      </section>
      {/* Platform Features */}
      <section className="platform-features">
        <h2>The Virtual Triage Platform Features</h2>
        <a href="https://portal.virtualtriage.ca/login">
          <button className="get-started-btn">Get Started →</button>
        </a>
        <div className="feature-cards">
          <div className="feature-card">
            <div className="icon">
              <img src="./assets/human.svg" alt="AI-Powered Symptom Analysis" />
            </div>
            <h3>AI-Powered Powered Precision</h3>
            <p>
              Unlike other platforms, Virtual Traige's AI is built specifically
              for healthcare, offering deeper symptom analysis and more accurate
              practitioner recommendations.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/virtualcall.svg"
                alt="E-Payments & E-Prescriptions"
              />
            </div>
            <h3>Streamlined Operations</h3>
            <p>
              Automate administrative tasks like appointment scheduling, symptom
              analysis, and client inquiries with our healthcare-trained AI.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/icon-5.svg"
                alt="E-Payments & E-Prescriptions"
                style={{ scale: "0.7" }}
              />
            </div>
            <h3>Marketplace for Health & Wellness</h3>
            <p>
              A marketplace where users find experts, and professionals showcase
              their services.
            </p>
          </div>
          <div className="feature-card">
            <div className="icon">
              <img
                src="./assets/calendar.svg"
                alt="E-Payments & E-Prescriptions"
              />
            </div>
            <h3>Cost & Time Saving</h3>
            <p>
              By reducing manual tasks and overhead, Virtual Triage saves time
              and costs, making health and wellness more efficient and
              accessible.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Plans */}
      <section className="pricing-plans">
        <h2>Pick your preferred plan</h2>
        <p>
          Flexible pricing plans designed to fit your needs. Choose the plan
          that works best for you and start delivering quality care today.
        </p>
        {/* Toggle Button */}
        <div className="toggle-switch">
          <button
            className={isMonthly ? "active" : ""}
            onClick={() => setIsMonthly(true)}
          >
            Monthly
          </button>
          <button
            className={!isMonthly ? "active" : ""}
            onClick={() => setIsMonthly(false)}
          >
            Yearly
          </button>
        </div>
        <p style={{ color: "black" }}>
          Upto <strong>16% OFF</strong> on Yearly Plans!
        </p>
        <div className="plans">
          <div className="plan-card basic-plan">
            <h3>Basic</h3>
            <p className="price">
              ${isMonthly ? pricing.basic.monthly : pricing.basic.yearly}
              <span style={{ fontSize: "18px" }}>
                {" "}
                / {isMonthly ? "month" : "year"}
              </span>
            </p>
            <p>Basic Package for single practitioners</p>
            <ul>
              <li>1 Practitioner</li>
              <li>Practitioner Portal</li>
              <li>Unlimited Appointments</li>
              <li>In App messaging</li>
              <li>Video Calling</li>
              <li>Advanced App. Management</li>
              <li>Visibility to patient’s map</li>
              <li>Healthcare trained AI chat bot</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/doctor_register">
              <button className="pricing-get-started-btn">Get Started</button>
            </a>
          </div>
          <div className="plan-card standard-plan">
            <h3>Standard</h3>
            <p className="price">
              ${isMonthly ? pricing.standard.monthly : pricing.standard.yearly}
              <span style={{ fontSize: "18px" }}>
                {" "}
                / {isMonthly ? "month" : "year"}
              </span>
            </p>
            <p>Everything in basic plus</p>
            <ul>
              <li>2-5 Practitioners</li>
              <li>Clinic Portal Access</li>
              <li>Advanced Scheduling System</li>
              <li>Appointments Management</li>
              <li>Practitioners Activity</li>
              <li>Revenue Monitoring</li>
              <li>Excel Exportable Data</li>
              <li>Patients Management</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/clinic_register">
              <button className="pricing-get-started-btn">Get Started</button>
            </a>
          </div>
          <div className="plan-card premium-plan">
            <h3>Premium</h3>
            <p className="price" style={{ color: "white" }}>
              ${isMonthly ? pricing.premium.monthly : pricing.premium.yearly}
              <span style={{ fontSize: "18px" }}>
                {" "}
                / {isMonthly ? "month" : "year"}
              </span>
            </p>
            <p>Standard but with higher limits</p>
            <ul>
              <li>6-25 Practitioners</li>
              <li>Clinic Portal Access</li>
              <li>Advanced Scheduling System</li>
              <li>Appointments Management</li>
              <li>Practitioners Activity</li>
              <li>Revenue Monitoring</li>
              <li>Excel Exportable Data</li>
              <li>Patients Management</li>
            </ul>
            <a href="https://portal.virtualtriage.ca/clinic_register">
              <button className="pricing-get-started-btn">Get Started</button>
            </a>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <footer className="contact">
        <div className="contact-section">
          <div className="contact-details">
            <h2>Let's get in touch!</h2>
            <p>
              Got questions about our Virtual Triage App? Our team is here to
              help. Contact us for quick and friendly support.
            </p>
            <div className="contact-info">
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#00a3bb", padding: "0 10px 0 10px" }}
                />{" "}
                +1 (437) 294 6051
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "#00a3bb", padding: "0 10px 0 10px" }}
                />{" "}
                teams@virtualtriage.com
              </p>
            </div>
            <h3>Connect with us!</h3>
            <div className="social-icons">
              <a href="youtube.com">
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.instagram.com/virtual.triage/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.facebook.com/virtualtriage?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/virtualtriage/">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://twitter.com/virtual_triage">
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
            </div>
          </div>

          <div className="contact-form-section">
            <h3>Subscribe for Newsletter!</h3>
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faUser} />
                <input type="text" placeholder="Full Name" name="from_name" />
              </div>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faEnvelope} />
                <input type="email" placeholder="Email" name="email" />
              </div>
              <button className="submit-button" type="submit" value="Send">
                Submit
              </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© 2024 Virtual Triage Healthcare-App</p>
      </div>
    </div>
  );
}

export default Secondpage;
