import React from "react";
import "./policy.css";
import { Link } from "react-router-dom";

function Privacypolicy() {
  return (
    <div style={{ backgroundColor: "#00a3bb" }}>
      <div
        className="text-center pb-1 pt-3"
        style={{ textAlign: "center", paddingTop: "25px" }}
      >
        <Link to="/">
          <img
            src="../assets/Virtual Triage.png"
            alt="Privacypolicy"
            style={{ width: "150px" }}
          />
        </Link>
      </div>
      <div className="content">
        <div className="Practitionersbox">
          <h2 className="text-white text-center">
            Privacy Policy, Terms & Conditions, and Payment Guidelines for
            Virtual Triage
          </h2>
          <p className="pt-3 pb-3">
            Welcome to Virtual Triage! We are committed to protecting your
            privacy and ensuring a secure, transparent experience for all users,
            whether you are a patient, a healthcare practitioner, or a clinic
            administrator. This document outlines our policies, terms, and
            guidelines for using the Virtual Triage platform.
          </p>
          <hr />
          <h1 className="pt-3 pph1">Privacy Policy</h1><hr />
          <h2>Information We Collect</h2>
          <p>We collect and process the following types of information based on user roles:</p>
          <h3>For Patients:</h3>
          <ul>
            <li><strong>Personal Information:</strong> Name, email, contact details, and demographic data.</li>
            <li><strong>Health Information:</strong> Symptoms, medical history, prescriptions, and appointment details.</li>
            <li><strong>Device and Usage Data:</strong> IP address, device details, and app interaction patterns.</li>
          </ul>
          <h3>For Practitioners and Clinic Admins:</h3>
          <ul>
            <li><strong>Professional Information:</strong> Name, contact details, licenses, certifications, and areas of expertise.</li>
            <li><strong>Practice Data:</strong> Availability schedules, consultation details, and service preferences.</li>
            <li><strong>Financial Information:</strong> Payment and subscription details.</li>
          </ul>
          <h3>How We Use Your Information</h3>
          <p>We use the information we collect to:</p>
          <ul>
            <li>Facilitate patient-practitioner matching, remote consultations, and e-prescriptions.</li>
            <li>Improve user experience by streamlining operations and workflows.</li>
            <li>Ensure compliance with data protection regulations such as HIPAA and GDPR.</li>
          </ul>
          <h3>Information Sharing</h3>
          <p>We only share information under the following circumstances:</p>
          <ul>
            <li><strong>Patients:</strong>Limited data is shared with practitioners to provide healthcare services.</li>
            <li><strong>Practitioners:</strong> Publicly available professional information is shared with patients for consultation purposes.</li>
            <li><strong>Third-Party Service Providers:</strong> For payment processing, secure video calls, and analytics.</li>
            <li><strong>Legal Compliance:</strong> To comply with laws or respond to lawful requests from public authorities.</li>
          </ul>
          <h3>Data Security</h3>
          <p>We use robust measures to protect all user data:</p>
          <ul>
            <li>Encryption during storage and transmission.</li>
            <li>Access controls to prevent unauthorized access.</li>
            <li>Regular audits to ensure ongoing compliance.</li>
          </ul>
          <h3>User Rights</h3>
          <p>All users have the right to:</p>
          <ul>
            <li>Access, update, or delete their personal information.</li>
            <li>Restrict or withdraw consent for data processing.</li>
            <li>File complaints with the relevant data protection authority.</li>
          </ul>
          <h3>Compliance with Google Play Policies</h3>
          <p>
            Virtual Triage fully adheres to Google Play policies to ensure a
            safe and transparent experience for all users:
          </p>
          <ul>
            <li>
              <strong>Data Security:</strong> Sensitive user data is handled
              securely in compliance with regulations like HIPAA and GDPR.
            </li>
            <li>
              <strong>Transparent Permissions:</strong> We request only
              necessary permissions for app functionality, such as camera,
              microphone, and location.
            </li>
            <li>
              <strong>No Deceptive Behavior:</strong> Virtual Triage does not
              engage in misrepresentation or misuse of user data.
            </li>
          </ul>

          <h3>Changes to Our Privacy Policy</h3>
          <p>
            We may update this Privacy Policy to reflect changes in our
            practices, services, or legal requirements. Any updates will be
            communicated through:
          </p>
          <ul>
            <li>In-app notifications or announcements.</li>
            <li>Email notifications sent to registered users.</li>
            <li>Updated policy links on our website and app.</li>
          </ul>

          <h3>Terms and Conditions</h3>
          <hr />
          <h3>Acceptance</h3>
          <p>
            By using the Virtual Triage platform, you agree to our Terms of Use,
            Privacy Policy, and related documents.
          </p>

          <h3>For Patients</h3>
          <ul>
            <li>
              <strong>Purpose:</strong> Virtual Triage enables patients to
              access healthcare professionals, book appointments, and manage
              healthcare services remotely.
            </li>
            <li>
              <strong>Responsibilities:</strong> Patients must provide accurate
              and updated information for effective use of the platform.
            </li>
          </ul>

          <h3>For Practitioners</h3>
          <ul>
            <li>
              <strong>Licensing:</strong> Practitioners must be legally licensed
              in their respective jurisdictions to use the platform.
            </li>
            <li>
              <strong>Confidentiality:</strong> Practitioners agree to maintain
              confidentiality in compliance with HIPAA, GDPR, or equivalent
              regulations.
            </li>
            <li>
              <strong>Service Standards:</strong> Healthcare services must meet
              the telemedicine standards of care for the practitioner’s region.
            </li>
          </ul>

          <h3>For Clinic Admins</h3>
          <ul>
            <li>
              <strong>Operational Oversight:</strong> Admins are responsible for
              managing appointments, practitioners, and patient records.
            </li>
            <li>
              <strong>Data Compliance:</strong> Clinics must ensure adherence to
              applicable legal and regulatory standards when using the platform.
            </li>
          </ul>

          <h3>Payment Guidelines</h3>
          <hr />
          <p>
            Payments for Virtual Triage services are handled securely through a
            third-party payment processor.
          </p>
          <ul>
            <li>
              <strong>Patients:</strong> Payments are processed per consultation
              or through subscription plans.
            </li>
            <li>
              <strong>Practitioners and Clinics:</strong> Subscription fees are
              charged monthly with clear details at sign-up.
            </li>
            <li>
              <strong>Refund Policy:</strong> Payments are non-refundable
              unless required by applicable laws.
            </li>
          </ul>

          <h3>Contact Us</h3>
          <p>
            If you have questions or concerns about this policy or Virtual
            Triage, please reach out:
          </p>
          <ul>
            <li>
              <strong>Emails:</strong> kreza@virtualtriage.ca
            </li>
            <li>
              <strong>Website:</strong> virtualtriage.ai
            </li>
            <li>
              <strong>Address:</strong> 50 John Street M5V 3T5, Toronto, ON, Toronto, ON, Canada, Ontario
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Privacypolicy;
