import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  faYoutube,
  faInstagram,
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import "../styles/doctorProfile.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import practitionerProfile from "../icons/doctor.jpg";

const localizer = momentLocalizer(moment);

function DoctorProfile() {
  const { name } = useParams(); // Get practitioner name from URL (if needed)
  const { state } = useLocation(); // Get practitioner state
  const [practitioner, setPractitioner] = useState(state?.practitioner || null); // State for practitioner details
  const [appointments, setAppointments] = useState([]); // Dummy appointments array
  const [showInviteModal, setShowInviteModal] = useState(false);
  const form = useRef(null); // Move useRef out of the conditional logic
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidePanelRef = useRef(null);
  const hamburgerRef = useRef(null);

  // Fallback to retrieve practitioner from localStorage if state is null
  useEffect(() => {
    if (!state?.practitioner) {
      const storedPractitioner = localStorage.getItem("selectedPractitioner");
      if (storedPractitioner) {
        setPractitioner(JSON.parse(storedPractitioner));
      }
    }
  }, [state]);

  useEffect(() => {
    // Show invite modal if practitioner is unverified
    if (practitioner && !practitioner.registered) {
      setShowInviteModal(true);
    }
  }, [practitioner]);

  const sendInvite = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Invitation sent successfully!");
          setShowInviteModal(false);
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send invitation. Please try again.");
        }
      );
  };

  // Close the menu when clicking outside of the side panel
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // Handle case where practitioner is not found
  if (!practitioner) {
    return <p>Loading practitioner details...</p>;
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="doctor-profile-page">
      {/* Header */}
      <header className="header">
        <nav className="nav">
          <div className="Nav-logo">
            <a href="Homepage" style={{ cursor: "default" }}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/VTLogo.png`}
                alt="VirtualTriage"
                style={{ width: "40vh" }}
              />
            </a>
          </div>
          <div
            className="navbar-hamburger"
            ref={hamburgerRef}
            onClick={toggleMenu}
          >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <div
            className={`side-panel ${isMenuOpen ? "open" : ""}`}
            ref={sidePanelRef}
          >
            <div className="nav-links">
              <a href="/">
                <button>Users</button>
              </a>
              <a href="/Secondpage">
                <button>Practitioners</button>
              </a>
              {/* <a href="./Practitioners">
                <button>Find Practitioner</button>
              </a> */}
              <button className="button-button-border">How It Works</button>
              <a href="https://portal.virtualtriage.ca/login">
                <button className="button-button-login">Login →</button>
              </a>
            </div>
          </div>
        </nav>
      </header>

      {/* Profile Header */}
      <header className="profile-header">
        <div className="left-section">
        <div className="profile-picture">
          <img src={practitionerProfile} alt="Practitioner" />
        </div>
        <div className="profile-details">
          <h1>{practitioner.Full_Name}</h1>
          <p>{practitioner.Doctor_Speciality}</p>
          <h2>
            {practitioner.City}, {practitioner.Country}
          </h2>
        </div>
        </div>

        {practitioner.registered ? (
          // Display calendar for verified practitioners
          <section className="right-header">
            <h3>Book an Appointment</h3>
            <Calendar
              localizer={localizer}
              events={appointments}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 400 }}
              selectable
              onSelectSlot={(slotInfo) =>
                alert(
                  `Selected: ${slotInfo.start.toLocaleTimeString()} - ${slotInfo.end.toLocaleTimeString()}`
                )
              }
            />
          </section>
        ) : (
          // Display constant notification for unverified practitioners
          <section className="invite-section">
            <div className="modal-content">
              <h3>
                Invite {practitioner.Full_Name} to Virtual Triage.
              </h3>
              <form ref={form} onSubmit={sendInvite}>
                <div className="form-group">
                  <label htmlFor="from_name">Your Name:</label>
                  <input type="text" name="from_name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Your Email:</label>
                  <input type="email" name="email" required />
                </div>
                <button type="submit" className="invite-btn">
                  Send Invite
                </button>
              </form>
            </div>
          </section>
        )}
      </header>

      {/* About Practitioner */}
      <section className="companies-section">
        <h2>About {practitioner.Full_Name}</h2>
        <p>{practitioner.Bio || "No bio available for this practitioner."}</p>
      </section>

      {/* Education */}
      <section className="education-section">
        <h2>Education</h2>
        {practitioner.Degree ? (
          <ul>
            <li>{practitioner.Degree}</li>
          </ul>
        ) : (
          <p>No education details available.</p>
        )}
      </section>

      {/* Contact Section */}
      <footer className="contact">
        <div className="contact-section">
          <div className="contact-details">
            <h2>Let's get in touch!</h2>
            <p>
              Got questions about our Virtual Triage App? Our team is here to
              help. Contact us for quick and friendly support.
            </p>
            <div className="contact-info">
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#00a3bb", padding: "0 10px 0 10px" }}
                />{" "}
                +1 (437) 294 6051
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ color: "#00a3bb", padding: "0 10px 0 10px" }}
                />{" "}
                teams@virtualtriage.com
              </p>
            </div>
            <h3>Connect with us!</h3>
            <div className="social-icons">
              <a href="youtube.com">
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.instagram.com/virtual.triage/">
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.facebook.com/virtualtriage?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/virtualtriage/">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
              <a href="https://twitter.com/virtual_triage">
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{ scale: "2", padding: "0 20px", color: "black" }}
                />
              </a>
            </div>
          </div>

          <div className="contact-form-section">
            <h3>Subscribe for Newsletter!</h3>
            <form className="contact-form" ref={form} onSubmit={sendInvite}>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faUser} />
                <input type="text" placeholder="Full Name" name="from_name" />
              </div>
              <div className="input-wrapper">
                <FontAwesomeIcon icon={faEnvelope} />
                <input type="email" placeholder="Email" name="email" />
              </div>
              <button className="submit-button" type="submit" value="Send">
                Submit
              </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>© 2024 Virtual Triage Healthcare-App</p>
      </div>
    </div>
  );
}

export default DoctorProfile;
